<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
.FontSize12px{
  font-size: 12px !important;
}
.FontSize22px {
  font-size: 22px !important;
}
.FontVariant1 {
  cursor: pointer !important;
  text-decoration: underline !important;
  color: blue !important;
}
.commonCursorPointer{
  cursor: pointer !important;
}
.FontStyleVariant1{
  line-height: 1.8 !important;
}
</style>
