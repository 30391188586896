<template>
  <div>
    <v-app-bar color="transparent">
      <v-img contain max-width="170px" src="@/assets/bankXchangelogo.png" />
    </v-app-bar>
    <v-card-text>
      <v-card flat outlined>
        <v-container fill-height height="100%" fluid>
          <v-layout align-center justify-center>
            <v-flex>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <div>
                    <v-img
                      src="@/assets/downloadapp.jpg"
                      class="commonCursorPointer"
                      @click="DownloadMethod('ANDROID_APP')"
                    />
                  </div>
                  <div class="text-center FontSize22px FontStyleVariant1">
                    Click here to
                    <span class="font-weight-bold FontVariant1" @click="DownloadMethod('ANDROID_APP')">Download</span>
                    the <span class="font-weight-bold">Android App Version (1.5.1)</span>.
                  </div>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <div>
                    <v-img
                      src="@/assets/testflight.jpg"
                      class="commonCursorPointer"
                      @click="DownloadMethod('TESTFLIGHT_APP')"
                    />
                  </div>
                  <div class="text-center FontSize22px">
                    Click here to
                    <span
                      class="font-weight-bold FontVariant1 FontStyleVariant1"
                      @click="DownloadMethod('TESTFLIGHT_APP')"
                      >Download</span
                    >
                    the  <span class="font-weight-bold">App Version (1.5.1)</span> from <span class="font-weight-bold">iOS App Store</span>.
                  </div>
                </v-col>
              </v-row>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: "Home",
  data: () => ({}),
  methods: {
    async DownloadMethod(action) {
      let url;
      if (action === "ANDROID_APP") {
        url = "https://raydeo-mumbai.s3.ap-south-1.amazonaws.com/bankXchangeAPK/app-release.apk";
      } else {
        url = "https://apps.apple.com/in/app/bankxchange/id6502450783";
      }
      const link = document.createElement("a");
      link.href = url;
      link.download = "bankXchange";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>
